import Vue from 'vue';
import Vuetify from 'vuetify';
import DatetimePicker from 'vuetify-datetime-picker';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import 'vuetify/dist/vuetify.min.css';
import './styles.css';
import { appData, loadAppDataFromStorage, saveAppDataToStorage } from './meter-data';
import logger from './logger';
import { APP_VERSION } from './site-consts';

const moduleLogger = logger.getLogger('main');
moduleLogger.info(`Loading v${APP_VERSION}`);

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(Vuetify);
Vue.use(DatetimePicker);
loadAppDataFromStorage();

new Vue({
    router: router,
    data: function data() {
        return {
            aData: appData,
        };
    },
    vuetify: new Vuetify({
        // theme: {
        //     dark: true,
        // },
    }),
    render: (h) => h(App),
    watch: {
        aData: {
            handler: function handler() {
                // logger.getLogger('app').debug('App data updated. Saving to local storage.');
                saveAppDataToStorage();
            },
            deep: true,
        },
    },
}).$mount('#app');

// Global variables for debug
window.appData = appData;
window.Vue = Vue;

// logger.getLogger('main').debug('App data loaded from storage.');
// logger.getLogger('main').debugall(`appData: ${JSON.stringify(appData)}`);
